import React from 'react';
import NextHead from 'next/head';
import getConfig from 'next/config';
import get from 'lodash/get';

import { PageShape } from '../../../shared/shapes';

const {
  publicRuntimeConfig: { BASE_URL },
} = getConfig();

const getProperty = (key, page, rootPage) =>
  get(page, key, get(rootPage, key, null));

function Head({ rootPage = {}, page = {} }) {
  const url = `${BASE_URL}/${page.slug}`;

  const pageImage = getProperty('pageImage.file.url', page, rootPage);
  const pageTitle = getProperty('pageTitle', page, rootPage);
  const pageDescription = getProperty('pageDescription', page, rootPage);

  return (
    <NextHead>
      <title>{`${pageTitle} | Project A`}</title>
      <meta name="description" content={pageDescription} />

      <meta property="og:title" content={pageTitle} />
      <meta property="og:site_name" content="Project A Website" />
      <meta property="og:type" content="website" />

      <meta property="og:description" content={pageDescription} />
      <meta property="og:url" content={url} />

      {/* Preload only the fonts that are displayed on the headers */}
      <link
        rel="preload"
        href="/fonts/IBM/IBMPlexSans-Regular.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="true"
      />
      <link
        rel="preload"
        href="/fonts/IBM/IBMPlexMono-Regular.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="true"
      />
      {pageImage && (
        <>
          <meta property="og:image" content={pageImage} />
          <meta name="twitter:image" content={pageImage} />
        </>
      )}

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:site" content="@ProjectAcom" />
      <meta name="twitter:description" content={pageDescription} />
      <meta name="twitter:url" content={url} />
    </NextHead>
  );
}

Head.propTypes = {
  rootPage: PageShape,
  page: PageShape,
};

export default Head;
