import React, { Children } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import NextLink from 'next/link';
import useTracking from '../../../shared/hooks/use-tracking/use-tracking';
import {
  EVENTS,
  CTA_TYPE,
} from '../../../shared/hooks/use-tracking/data-layer-variables';
import { removeTrailingSlash } from '../../../shared/utils/url/url';

function Link({
  children,
  href,
  as,
  activeClass,
  navigatingClass,
  partialActiveClass,
  isExternalUrl,
  trackingData,
}) {
  const { pushTracking } = useTracking();
  const child = Children.only(children);
  const { asPath } = useRouter();

  const path = as || href;
  const isAnchor = path.startsWith('#');

  const { onClick: onClickChild, ...childProps } = child.props;

  // This check makes sure that paths with query should still match, i.e `/team?dept=frontend` and `/team`
  const asPathWithoutTrailingSlash = removeTrailingSlash(asPath.split('?')[0]);
  const arePathsEqual = asPathWithoutTrailingSlash === path;

  const className = classNames(childProps.className, navigatingClass, {
    [partialActiveClass]: arePathsEqual && partialActiveClass,
    [activeClass]: arePathsEqual && activeClass,
  });

  if (isAnchor) {
    return React.cloneElement(child, {
      ...childProps,
      className,
      href: path,
      onClick: event => {
        pushTracking(trackingData);
        const element = document.querySelector(path);

        if (element && element.scrollIntoView) {
          event.preventDefault();

          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }

        if (onClickChild) {
          onClickChild(event);
        }
      },
    });
  }

  if (isExternalUrl) {
    return React.cloneElement(child, {
      ...childProps,
      className,
      href: path,
      target: '_blank',
      rel: 'noopener noreferrer',
      onClick: () => pushTracking(trackingData),
    });
  }

  return (
    <NextLink href={path} legacyBehavior>
      {React.cloneElement(child, {
        ...childProps,
        className,
        href: path,
        onClick: e => {
          pushTracking(trackingData);

          if (onClickChild) {
            onClickChild(e);
          }
        },
      })}
    </NextLink>
  );
}

Link.propTypes = {
  isExternalUrl: PropTypes.bool,
  router: PropTypes.any,
  children: PropTypes.any,
  activeClass: PropTypes.string,
  partialActiveClass: PropTypes.string,
  href: PropTypes.string,
  as: PropTypes.string,
  url: PropTypes.string,
  navigatingClass: PropTypes.string,
  trackingData: PropTypes.shape({
    event: PropTypes.oneOf(Object.values(EVENTS)).isRequired,
    ctaType: PropTypes.oneOf(Object.values(CTA_TYPE)).isRequired,
    label: PropTypes.string,
  }).isRequired,
};

export default Link;
