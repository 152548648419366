export const JOB_PAGE_BACKGROUND_ELEMENTS_CONFIG = [
  {
    verticalPosition: 'top',
    horizontalPosition: 'center',
    size: 'medium',
  },
  {
    verticalPosition: 'bottom',
    horizontalPosition: 'right',
    size: 'medium',
  },
];

export function getBackgroundElementsFor(page) {
  if (!page) {
    return { topAndMiddleBackgroundElements: [], bottomBackgroundElements: [] };
  }

  if (!page.backgroundElements && isJobPage(page)) {
    page.backgroundElements = JOB_PAGE_BACKGROUND_ELEMENTS_CONFIG;
  }

  const topAndMiddleBackgroundElements = page.backgroundElements
    ? page.backgroundElements.filter(
        ({ verticalPosition }) => verticalPosition !== 'bottom'
      )
    : [];

  const bottomBackgroundElements = page.backgroundElements
    ? page.backgroundElements.filter(
        ({ verticalPosition }) => verticalPosition === 'bottom'
      )
    : [];

  return { topAndMiddleBackgroundElements, bottomBackgroundElements };
}

const isJobPage = page =>
  page.pageTitle && page.pageTitle.toLowerCase().includes('job');
