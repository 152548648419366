import React from 'react';
import PropTypes from 'prop-types';

import { LinkShape } from '../../../../shared/shapes';
import {
  EVENTS,
  CTA_TYPE,
} from '../../../../shared/hooks/use-tracking/data-layer-variables';

import Link from '../../../../components/global/link/link';
import styles from './footer-navigation.module.scss';

function FooterNavigation({ websiteLinks, socialMediaLinks }) {
  return (
    <nav className={styles.navigation} role="navigation">
      <div className={styles.inner}>
        <ul className={styles.websiteLinks}>
          {websiteLinks.map(link => (
            <li key={link.url} className={styles.websiteLinkItem}>
              <Link
                href={link.url}
                partialActiveClass={styles.active}
                navigatingClass={styles.navigating}
                isExternalUrl={link.isExternalUrl}
                trackingData={{
                  event: EVENTS.CLICK_CTA_NAV,
                  ctaType: CTA_TYPE.FOOTER_NAV,
                  label: link.label,
                }}
              >
                <a className={styles.websiteLink}>
                  {link.pageTitle || link.label}
                </a>
              </Link>
            </li>
          ))}
        </ul>

        <ul className={styles.socialLinks}>
          {socialMediaLinks.map(link => (
            <li key={link.url} className={styles.socialLinkItem}>
              <Link
                href={link.url}
                isExternalUrl={link.isExternalUrl}
                trackingData={{
                  event: EVENTS.CLICK_SOCIAL,
                  ctaType: CTA_TYPE.PA_SOCIAL,
                  label: link.name,
                }}
              >
                <a className={styles.socialLink}>
                  <picture>
                    <source srcSet={link.icon.file && link.icon.file.url} />
                    <img
                      src={link.icon.file && link.icon.file.url}
                      alt={link.icon.title}
                    />
                  </picture>
                </a>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
}

FooterNavigation.propTypes = {
  websiteLinks: PropTypes.arrayOf(LinkShape),
  socialMediaLinks: PropTypes.arrayOf(LinkShape),
};

export default FooterNavigation;
