import React, { useContext } from 'react';
import NextHead from 'next/head';

import { GDPR } from '../../../shared/content';
import {
  COOKIE_POLICIES,
  getGtmMarkups,
} from '../../../services/cookies/cookies';

import Button, { MODIFIERS } from '../../../components/global/button/button';
import { CookieContext } from '../../../shared/context/cookie-context';
import Link from '../link/link';
import Portal from './portal';

import styles from './cookie-banner.module.scss';

function CookieBanner() {
  const { cookie, setCookie } = useContext(CookieContext);

  if (cookie === COOKIE_POLICIES.ACCEPT_ALL) {
    const [gtmScript, gtmIframe] = getGtmMarkups();

    return (
      <>
        <NextHead>
          <script
            dangerouslySetInnerHTML={{
              __html: gtmScript,
            }}
          />
        </NextHead>
        <Portal selector="#gtm">
          {gtmIframe && (
            <iframe
              src={gtmIframe}
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}
            />
          )}
        </Portal>
      </>
    );
  } else if (cookie === COOKIE_POLICIES.NOT_SELECTED) {
    return (
      <aside className={styles.cookieBanner}>
        <div className={styles.inner}>
          <div className={styles.center}>
            <div className={styles.content}>
              <p className={styles.text}>{GDPR.TEXT}</p>
              <p className={styles.text}>
                See also our{' '}
                <Link href="/privacy-policy">
                  <a>Privacy policy</a>
                </Link>{' '}
                or{' '}
                <Link href="/imprint">
                  <a>Imprint</a>
                </Link>
                .
              </p>
            </div>

            <div className={styles.buttons}>
              <Button
                title={GDPR.DECLINE}
                onClick={() => setCookie(COOKIE_POLICIES.DECLINE_ALL)}
                modifiers={[MODIFIERS.SECONDARY, MODIFIERS.HOVER_BLACK]}
              />

              <Button
                title={GDPR.ACCEPT}
                onClick={() => setCookie(COOKIE_POLICIES.ACCEPT_ALL)}
                modifiers={[MODIFIERS.PRIMARY, MODIFIERS.HOVER_BLACK]}
              />
            </div>
          </div>
        </div>
      </aside>
    );
  } else {
    return null;
  }
}

CookieBanner.propTypes = {};

export default CookieBanner;
