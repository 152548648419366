import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './button.module.scss';

function Button({
  title,
  modifiers = [],
  onClick,
  type = 'button',
  disabled = false,
}) {
  return (
    <button
      className={classNames(
        styles.button,
        modifiers.map(className => styles[className])
      )}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </button>
  );
}

export const MODIFIERS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  HOVER_BLACK: 'hoverBlack',
  HOVER_BLUE: 'hoverBlue',
  LARGE: 'large',
};

Button.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
  modifiers: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
};

export default Button;
