import { useTranslatedContent } from './use-translated-content';

import * as APPLICATION_FORM from './application-form.json';
import * as JOB_POST from './job-post.json';
import * as GDPR from './gdpr.json';
import * as ALL_EYES_ON from './all-eyes-on.json';
import * as PRESS from './press.json';

export {
  useTranslatedContent,
  APPLICATION_FORM,
  JOB_POST,
  GDPR,
  ALL_EYES_ON,
  PRESS,
};
