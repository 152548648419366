import Cookies from 'js-cookie';
import getConfig from 'next/config';
import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

export const COOKIE_NAME = 'COOKIE_POLICY';
export const COOKIE_OPTIONS = { expires: 365 };

export const COOKIE_POLICIES = {
  NOT_SELECTED: 'NOT_SELECTED',
  ACCEPT_ALL: 'ACCEPT_ALL',
  DECLINE_ALL: 'DECLINE_ALL',
};

export const getCurrentCookiePolicy = () =>
  Cookies.get(COOKIE_NAME) || COOKIE_POLICIES.NOT_SELECTED;

export const setCookiePolicy = policy =>
  Cookies.set(COOKIE_NAME, policy, COOKIE_OPTIONS);

export const getGtmMarkups = () => {
  const {
    publicRuntimeConfig: { GTM_CONTAINER_ID },
  } = getConfig();

  return GTM_CONTAINER_ID
    ? [
        `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${GTM_CONTAINER_ID}');`,
        `https://www.googletagmanager.com/ns.html?id=${GTM_CONTAINER_ID}`,
      ]
    : ['', null];
};

export const getGoogleAnalyticsUserId = () => Cookies.get('_ga');

export const getGoogleAnalyticsSessionId = () => Cookies.get('_gid');

const isValidCookiePolicy = cookie =>
  Object.values(COOKIE_POLICIES).includes(cookie);

/**
 * Hook that takes care of reading/setting cookie values
 * that could happen when interacting with the website or if the value comes from an external website.
 * This is not meant to be re-used and was extracted only for readability purposes.
 * If you need access to the cookie value, use the CookieContext.
 * @returns {selectedPolicy: string | null, setSelectedPolicy: () => void}
 */
export const useCookiePolicy = () => {
  const { query, isReady } = useRouter();
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const cookieInStorageOrDefault = getCurrentCookiePolicy();

  useEffect(() => {
    if (!isReady) {
      return;
    }

    /**
     * @param {COOKIE_POLICIES | undefined} inside_cookie - a query value that inside.project-a.com provides.
     */
    const cookieFromURL = query['inside_cookie'];

    if (isValidCookiePolicy(cookieFromURL)) {
      setSelectedPolicy(cookieFromURL);
    } else if (isValidCookiePolicy(cookieInStorageOrDefault)) {
      setSelectedPolicy(cookieInStorageOrDefault);
    }
  }, [query, isReady, cookieInStorageOrDefault]);

  useEffect(() => {
    if (isValidCookiePolicy(selectedPolicy)) {
      setSelectedPolicy(selectedPolicy);
      setCookiePolicy(selectedPolicy);
    }
  }, [selectedPolicy]);

  return [selectedPolicy, setSelectedPolicy];
};
