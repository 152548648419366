export const EVENTS = {
  ALL_EYES_ON: 'click_all_eyes_on_navigation',
  ALL_EYES_ON_CTA: 'click_all_eyes_on_cta',
  CLICK_CONTENT_LINK: 'click_content_link',
  CLICK_CTA_JOB: 'click_cta_job',
  CLICK_CTA_KNOWLEDGE: 'click_knowledge_content',
  CLICK_CTA_NAV: 'click_cta_navigation',
  CLICK_CTA_PAKCON: 'click_cta_pakcon',
  CLICK_CTA_PRESS: 'click_cta_press',
  CLICK_CTA_PRESS_CONTENT: 'click_cta_press_content',
  CLICK_CTA_HOMEPAGE: 'click_cta_homepage',
  CLICK_CTA_TEAM: 'click_cta_team',
  CLICK_CTA_TEASER: 'click_cta_teaser',
  CLICK_VENTURE: 'click_venture',
  CLICK_IMAGE: 'click_image',
  CLICK_PRESS_ASSETS: 'click_press_asset',
  CLICK_SOCIAL: 'click_social',
  TOGGLE_FAQ: 'toggle_faq',
  JOB_DESCRIPTION_START: 'job_description_start',
  JOB_SUBMISSION: 'job_application_submission',
  PAGE_VIEW: 'pageview',
  SELECT_FILTER: 'select_filter',
  SLIDER_NAVIGATION: 'click_slider_navigation',
  TOGGLE_FILTER: 'toggle_filter',
  TOGGLE_FILTER_GROUP: 'toggle_filter_group',
  SUCCESS_STORIES_SLIDER: 'click_cta_success_stories',
};

export const CTA_TYPE = {
  CAREER_FILTER: 'career_filter',
  CAREER_LINKS: 'career_links',
  CAREER_PRIVACY_POLICY: 'career_privacy_policy',
  CONTENT_ITEM_IMAGE: 'content_item_image',
  CONTENT_ITEM_LINK: 'content_item_link',
  CONTENT_ITEM_SEE_ALL: 'content_item_see_all',
  DOWNLOAD: 'download',
  FEATURED_LINK: 'featured_link',
  FILTERS_KNOWLEDGE: 'knowledge_filters',
  FILTERS_TEAM: 'filters_team',
  FILTERS_VENTURES: 'ventures_filters',
  HOMEPAGE_SECTION_LINK: 'homepage_section_link',
  ITEM_SLIDER_IMAGE: 'item_slider_image',
  ITEM_SLIDER_TEXT: 'item_slider_text',
  JOB_NAME: 'job_name',
  JOB_ARROW: 'job_arrow',
  JOB_LANGUAGE_CHANGE: 'job_language_change',
  KNOWLEDGE_HERO: 'knowledge_hero',
  OUR_TEAMS: 'Our Teams',
  NAV_LOGO: 'Logo',
  NAV_PORTFOLIO: 'Portfolio',
  NAV_TEAM: 'Team',
  NAV_SUCCESS_STORIES: 'Success',
  NAV_CAREERS: 'Career',
  NAV_KNOWLEDGE: 'Knowledge',
  NAV_NEWS: 'News',
  NAV_INSIGHTS: 'Insights',
  NAV_STUDIO: 'Studio',
  NAV_MOBILE_MENU: 'toggle_mobile_menu',
  FOOTER_NAV: 'footer_navigation',
  PAKCON_2019: 'pakcon_2019',
  PAKCON_APPLY: 'pakcon_apply',
  PA_SOCIAL: 'project_a_social_media',
  PRESS_OVERVIEW_BACK: 'press_overview_back',
  PRESS_SEE_ALL_ARTICLES: 'press_see_all_articles',
  RETURN_CAREER_PAGE: 'return_career_page',
  SLIDER_CONTROL_ARROWS: 'slider_control_arrows',
  SLIDER_CONTROL_DOTS: 'slider_control_dots',
  SUCCESS_STORIES_FULL: 'success_stories_full_story',
  SUCCESS_STORIES_ALL: 'success_stories_see_all',
  TEAM_MEMBER_SOCIAL: 'team_member_social_media',
  TEAM_CUSTOM_LINK: 'team_custom_link',
  TEAM_BIO_PAGE: 'team_bio_page',
  TEAM_SOCIAL_MEDIA: 'team_social_media',
  TEASER: 'teaser_cta',
  VENTURE_WEBSITE_HEADER_LINK: 'venture_website_header_link',
  VENTURE_WEBSITE_INFO_LINK: 'venture_website_info_link',
  VENTURE_CLOSE_DETAIL: 'venture_close_detail',
  VENTURE_OPEN_DETAIL: 'venture_open_detail',
};
