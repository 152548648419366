import { useState, useEffect, useRef } from 'react';

function useScrollingNavigation() {
  const [isScrolled, setScrolled] = useState(false);
  const [isHidden, setHidden] = useState(false);

  const scrollTop = useRef(0);

  useEffect(() => {
    const getWindowScrollTop = () =>
      window.pageYOffset || document.documentElement.scrollTop;

    const updateScroll = () => {
      const newScrollTop = getWindowScrollTop();

      if (Math.abs(scrollTop.current - newScrollTop) > 20) {
        const scrollDirection =
          scrollTop.current > newScrollTop ? 'up' : 'down';

        setHidden(scrollDirection === 'down' && newScrollTop > 0);
        scrollTop.current = newScrollTop;
      }

      setScrolled(newScrollTop > 0);
    };

    window.addEventListener('scroll', updateScroll);
    updateScroll();

    return () => {
      window.removeEventListener('scroll', updateScroll);
    };
  }, [scrollTop]);

  return [isScrolled, isHidden];
}

export default useScrollingNavigation;
