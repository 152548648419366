import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';
import { useRouter } from 'next/router';
import { RichTextShape } from '../../../shared/shapes';
import {
  EVENTS,
  CTA_TYPE,
} from '../../../shared/hooks/use-tracking/data-layer-variables';

import Link from '../../global/link/link';
import styles from './featured-link.module.scss';

export const VISIBILITY_OPTIONS = {
  'Only Home': 'Only Home',
  All: 'All',
};

const HOMEPAGE_PATHS = ['/', '/home-2/'];

/* eslint-disable react/display-name */
const RICH_TEXT_OPTIONS = {
  renderNode: {
    [INLINES.HYPERLINK]: node => {
      const url = get(node, 'data.uri', '');
      const labelText = get(node, 'content[0].value', '');

      return (
        <Link
          href={url}
          isExternalUrl
          trackingData={{
            event: EVENTS.CLICK_CONTENT_LINK,
            ctaType: CTA_TYPE.CONTENT_ITEM_LINK,
            label: url,
          }}
        >
          <a>{labelText}</a>
        </Link>
      );
    },
  },
};

function FeaturedLink({ featured }) {
  const router = useRouter();
  const { content, visibility } = featured;
  const isHomepage = HOMEPAGE_PATHS.some(page => router.asPath === page);

  if (visibility === 'Only Home' && !isHomepage) {
    return null;
  }

  return (
    <div id="featured" className={styles.featuredLink}>
      <div className={styles.container}>
        {content && documentToReactComponents(content, RICH_TEXT_OPTIONS)}
      </div>
    </div>
  );
}

FeaturedLink.propTypes = {
  featured: PropTypes.shape({
    content: RichTextShape,
    visibility: PropTypes.oneOf(Object.keys(VISIBILITY_OPTIONS)),
  }),
};

export default FeaturedLink;
