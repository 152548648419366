import qs from 'query-string';

const TRANSFORMATION_WHITELIST = ['w', 'h', 'fm', 'fit', 'r', 'q', 'bg'];

export function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export function getPageSlug(url) {
  if (url === '/') {
    return url;
  }
  const slugs = removeTrailingSlash(url).split('/');
  return slugs[slugs.length - 1];
}

export function removeTrailingSlash(string) {
  return string.replace(/(.+)(\/)$/g, '$1');
}

export function getHash() {
  return window.location.hash.split('#')[1] || '';
}

export function getHashParams() {
  return qs.parse(getHash());
}

export function setHashParams(params) {
  window.location.hash = qs.stringify(params);
}

export function getHashParamsForId(id) {
  const initialParams = getHashParams();

  const filteredParams = Object.keys(initialParams)
    .filter(key => key.startsWith(id))
    .reduce((params, key) => ({ ...params, [key]: initialParams[key] }), {});

  return filteredParams;
}

export function getHashParamsNotForId(id) {
  const initialParams = getHashParams();

  const filteredParams = Object.keys(initialParams)
    .filter(key => !key.startsWith(id))
    .reduce((carry, key) => ({ ...carry, [key]: initialParams[key] }), {});

  return filteredParams;
}

export function getFilterUrlParams(id) {
  const initialParams = getHashParamsForId(id);

  return Object.keys(initialParams).reduce((values, key) => {
    const newKey = key.replace(new RegExp(`${id}.`, 'g'), '');
    values[newKey] = initialParams[key];

    return values;
  }, {});
}

export function removeProtocol(url) {
  return url ? url.replace(/(^\w+:|^)\/\//, '') : '';
}

export function getTransformationMediaUrl(media, transformations = {}) {
  const { url } = media.file;

  const transformationString = Object.entries(transformations)
    .filter(([key]) => TRANSFORMATION_WHITELIST.includes(key))
    .sort(([keyA], [keyB]) => (keyA > keyB ? -1 : 1))
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join('&');

  return transformationString ? `${url}?${transformationString}` : url;
}

export function removePathExtensionFromHistory(path) {
  if (path.endsWith('/index.html')) {
    window.history.replaceState(null, null, path.replace('/index.html', ''));
  }
}
