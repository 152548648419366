import '@/shared/polyfills';

import React, { Fragment, useEffect } from 'react';
import Router from 'next/router';
import { PageTransition } from 'next-page-transitions';
import PropTypes from 'prop-types';

import '@/shared/style-resources/styles/global.scss';

import styles from './_app.module.scss';
import Head from '@/components/global/head/head';

import CookieBanner from '@/components/global/cookie-banner/cookie-banner';
import HeaderNavigation from '@/components/global/navigation/header-navigation/header-navigation';
import FooterNavigation from '@/components/global/navigation/footer-navigation/footer-navigation';
import Bubble from '@/components/global/bubble/bubble';

import {
  getCurrentCookiePolicy,
  COOKIE_POLICIES,
} from '@/services/cookies/cookies';
import { initErrorMonitor } from '@/services/error-monitor/error-monitor';
import { CookieContextProvider } from '@/shared/context/cookie-context';
import { removePathExtensionFromHistory } from '@/shared/utils/url/url';
import { getBackgroundElementsFor } from '@/shared/utils/background-helper/background-helper';
import { PageShape } from '@/shared/shapes';

initErrorMonitor();

const getPageGroupLevels = window => {
  return window.location.pathname
    .split('/')
    .filter(p => p.length)
    .reduce((res, current, index) => {
      res[`pageGroupLevel_${index + 1}`] = current;
      return res;
    }, {});
};

function ProjectAWebsiteApp({ Component, pageProps }) {
  const { rootPage = {}, page } = pageProps;
  const {
    headerNavigation,
    footerNavigation,
    socialMediaNavigation,
    featured,
  } = rootPage;

  const { topAndMiddleBackgroundElements, bottomBackgroundElements } =
    getBackgroundElementsFor(page);

  const topAndMiddleBubbles = topAndMiddleBackgroundElements.map(
    (elementProps, index) => (
      <Bubble key={`bubble-${page.id}-${index}`} {...elementProps} />
    )
  );

  const bottomBubbles = bottomBackgroundElements.map((elementProps, index) => (
    <Bubble key={`bubble-${page.id}-${index}`} {...elementProps} />
  ));

  useEffect(() => {
    window.history.scrollRestoration = 'manual';

    removePathExtensionFromHistory(window.location.pathname);

    if (getCurrentCookiePolicy() !== COOKIE_POLICIES.DECLINE_ALL) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'pageview',
        ...getPageGroupLevels(window),
      });
    }

    Router.events.on('routeChangeComplete', () => {
      window.scrollTo(0, 0);

      if (getCurrentCookiePolicy() !== COOKIE_POLICIES.DECLINE_ALL) {
        window.dataLayer.push({
          event: 'pageview',
          ...getPageGroupLevels(window),
        });
      }
    });
  }, []);

  return (
    <CookieContextProvider>
      <Head page={page} rootPage={rootPage} />

      <CookieBanner />

      <div className={styles.layout}>
        <header className={styles.navigation}>
          <HeaderNavigation
            headerNavigation={headerNavigation}
            featured={featured}
          />
        </header>

        <main className={styles.center}>
          <PageTransition
            timeout={300}
            loadingTimeout={300}
            classNames="page-transition"
            loadingClassNames=""
            loadingComponent={<div>Loading</div>}
            skipInitialTransition={true}
          >
            <Fragment key={Math.random()}>
              <div className="bg-elements-container">{topAndMiddleBubbles}</div>

              <Component {...pageProps} />

              <div className="bg-elements-container">{bottomBubbles}</div>
            </Fragment>
          </PageTransition>
        </main>

        {footerNavigation && (
          <footer className={styles.footer}>
            <FooterNavigation
              websiteLinks={footerNavigation.links}
              socialMediaLinks={socialMediaNavigation.links}
            />
          </footer>
        )}
      </div>
    </CookieContextProvider>
  );
}

ProjectAWebsiteApp.propTypes = {
  Component: PropTypes.any,
  pageProps: PropTypes.shape({
    rootPage: PageShape,
    page: PageShape,
  }),
};

export default ProjectAWebsiteApp;
