import { useState } from 'react';

export function useTranslatedContent(contentModule, lang = 'EN') {
  const translatedContentModule = Object.keys(contentModule).reduce(
    (translatedContent, key) => {
      if (typeof contentModule[key] !== 'object') {
        throw new Error(
          `Invalid structure for translatable content for 
          ${JSON.stringify(contentModule)}. 
          Entry structure should be e.g. {"${lang}": [content goes here]}`
        );
      }

      const translation = contentModule[key][lang] || contentModule[key]['EN'];

      return {
        ...translatedContent,
        [key]: translation,
      };
    },
    {}
  );

  const [content] = useState(translatedContentModule);

  return content;
}
