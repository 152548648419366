import { useState } from 'react';

import { useDisabledBackgroundScroll } from '../../../../shared/hooks/use-disabled-background-scroll/use-disabled-background-scroll';
import {
  CTA_TYPE,
  EVENTS,
} from '../../../../shared/hooks/use-tracking/data-layer-variables';
import useTracking from '../../../../shared/hooks/use-tracking/use-tracking';

function useMobileMenu() {
  const [menuActive, setMenuActive] = useState(false);
  const { pushTracking } = useTracking();
  useDisabledBackgroundScroll(menuActive);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
    pushTracking({
      event: EVENTS.CLICK_CTA_NAV,
      ctaType: CTA_TYPE.NAV_MOBILE_MENU,
      isActive: !menuActive,
    });
  };

  const closeMenu = () => {
    setMenuActive(false);
    if (menuActive) {
      pushTracking({
        event: EVENTS.CLICK_CTA_NAV,
        ctaType: CTA_TYPE.NAV_MOBILE_MENU,
        isActive: false,
      });
    }
  };

  return [menuActive, toggleMenu, closeMenu];
}

export default useMobileMenu;
