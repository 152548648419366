import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Link from '../../../../components/global/link/link';
import { LinkShape, RichTextShape } from '../../../../shared/shapes';
import {
  EVENTS,
  CTA_TYPE,
} from '../../../../shared/hooks/use-tracking/data-layer-variables';
import FeaturedLink, {
  VISIBILITY_OPTIONS,
} from '../../../content/featured-link/featured-link';
import MenuButton from './menu-button/menu-button';
import useScrollingNavigation from './use-scrolling-navigation';
import useMobileMenu from './use-mobile-menu';
import styles from './header-navigation.module.scss';

function Logo({ closeMenu }) {
  return (
    <Link
      href="/"
      trackingData={{
        event: EVENTS.CLICK_CTA_NAV,
        ctaType: CTA_TYPE.NAV_LOGO,
      }}
    >
      <a className={styles.home} onClick={closeMenu}>
        Project A
      </a>
    </Link>
  );
}

function NavigationItems({ links, closeMenu }) {
  return (
    <ul className={styles.links}>
      {links.map(link => {
        return (
          <li onClick={closeMenu} key={link.url} className={styles.linkItem}>
            <Link
              href={link.url}
              partialActiveClass={styles.active}
              navigatingClass={styles.navigating}
              trackingData={{
                event: EVENTS.CLICK_CTA_NAV,
                ctaType: link.label,
              }}
              isExternalUrl={link.isExternalUrl}
            >
              <a className={styles.link}>{link.pageTitle || link.label}</a>
            </Link>
          </li>
        );
      })}
    </ul>
  );
}

function HeaderNavigation({ headerNavigation, featured }) {
  const [menuActive, toggleMenu, closeMenu] = useMobileMenu();
  const [isScrolled, isHidden] = useScrollingNavigation();

  return (
    <div
      className={classNames(styles.navigation, {
        [styles.menuActive]: menuActive,
        [styles.scrolled]: isScrolled,
        [styles.hidden]: isHidden,
      })}
    >
      {featured && <FeaturedLink featured={featured} />}

      {headerNavigation && (
        <nav role="navigation">
          <div className={styles.inner}>
            <Logo closeMenu={closeMenu} />

            <div className={styles.menuToggle}>
              <MenuButton active={menuActive} onClick={toggleMenu} />
            </div>

            <NavigationItems
              links={headerNavigation.links}
              closeMenu={closeMenu}
            />
          </div>
        </nav>
      )}
    </div>
  );
}

Logo.propTypes = {
  closeMenu: PropTypes.func.isRequired,
};

NavigationItems.propTypes = {
  links: PropTypes.arrayOf(LinkShape),
  closeMenu: PropTypes.func.isRequired,
};

HeaderNavigation.propTypes = {
  headerNavigation: PropTypes.shape({
    links: PropTypes.arrayOf(LinkShape),
  }),
  featured: PropTypes.shape({
    content: RichTextShape,
    visibility: PropTypes.oneOf(Object.keys(VISIBILITY_OPTIONS)),
  }),
};

export default HeaderNavigation;
