import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './menu-button.module.scss';

function MenuButton({ active, onClick }) {
  return (
    <button
      className={classNames(styles.menuButton, { [styles.active]: active })}
      title="Toggle Menu"
      onClick={onClick}
    >
      <div className={classNames(styles.bar, styles.barTop)} />
      <div className={classNames(styles.bar, styles.barMiddle)} />
      <div className={classNames(styles.bar, styles.barBottom)} />
    </button>
  );
}

MenuButton.propTypes = {
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default MenuButton;
