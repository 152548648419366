import { useEffect, useRef } from 'react';

export const useDisabledBackgroundScroll = shouldDisable => {
  const verticalScrollPosition = useRef(0);

  useEffect(() => {
    let { body } = document;
    let { position } = window.getComputedStyle(body);

    let isFullScreen = position === 'fixed';

    if (shouldDisable) {
      verticalScrollPosition.current = window.scrollY;
      body.classList.add('scroll-disabled');
    } else {
      body.classList.remove('scroll-disabled');

      if (isFullScreen) {
        window.scrollTo(0, verticalScrollPosition.current);
      }
    }

    return () => {
      body.style.top = `-${window.scrollY}px`; // prevent scroll jump while transitions are active
    };
  }, [shouldDisable]);
};
