import * as Sentry from '@sentry/browser';
import getConfig from 'next/config';

export function initErrorMonitor() {
  const {
    publicRuntimeConfig: { SENTRY_DSN, BUILD_ID, ENV_NAME, IS_PRODUCTION },
  } = getConfig();

  Sentry.configureScope(scope => {
    scope.setTag('build-id', BUILD_ID);
    scope.setTag('env-name', ENV_NAME);
  });

  Sentry.init({
    enabled: !!(IS_PRODUCTION && SENTRY_DSN),
    dsn: SENTRY_DSN,
  });
}

export const captureException = Sentry.captureException;
