import {
  array,
  arrayOf,
  string,
  object,
  bool,
  shape,
  number,
  any,
  oneOfType,
} from 'prop-types';

export const MediaShape = shape({
  id: string,
  createdAt: string,
  title: string,
  description: string,
  file: shape({
    url: string,
    fileName: string,
    details: shape({
      image: shape({
        width: number,
        height: number,
      }),
      size: number,
    }),
  }),
});

export const LinkShape = shape({
  id: string,
  label: string,
  isExternalUrl: bool,
  url: string,
  icon: MediaShape,
});

export const RichTextShape = shape({
  content: array,
  data: object,
  createdAt: string,
});

export const VentureShape = shape({
  content: RichTextShape,
  contentType: string,
  createdAt: string,
  exited: bool,
  focus: bool,
  headline: string,
  id: string,
  logo: MediaShape,
  subheadline: string,
  updatedAt: string,
  visible: bool,
  highlightContent: object,
  highlightInformationList: object,
  homepageUrl: string,
});

export const FilterOptionShape = shape({
  value: string.isRequired,
  label: string.isRequired,
  enabled: bool.isRequired,
  active: bool,
});

export const FilterGroupShape = shape({
  property: string.isRequired,
  label: string.isRequired,
  active: bool,
  options: arrayOf(FilterOptionShape),
});

export const TeamMemberShape = shape({
  contentType: string,
  createdAt: string,
  headline: string,
  subheadline: string,
  id: string,
  slug: string,
  type: string,
  updatedAt: string,
  visible: bool,
  picture: object,
  industryFocus: string,
  geographicalFocus: string,
  expertise: string,
  position: string,
  customLink: string,
  tag: string,
  team: arrayOf(string),
});

export const NavigationShape = shape({
  title: string,
  links: arrayOf(LinkShape),
});

export const PageShape = shape({
  slug: string,
  pageTitle: string,
  pageDescription: string,
  headerNavigation: NavigationShape,
  content: array,
  footerNavigation: NavigationShape,
  featured: LinkShape,
  socialMediaNavigation: NavigationShape,
  pageImage: MediaShape,
});

export const KnowledgeItemShape = shape({
  title: string,
  tileDisplay: string,
  headline: string,
  subheadline: string,
  date: string,
  link: LinkShape,
  mediaType: string,
  mediaSubtype: string,
  topic: string,
  tags: any, // TODO: specify,
  eventDate: string,
  eventLocation: string,
  eventDuration: string,
  picture: object,
});

export const GreenhouseDataShape = shape({
  id: number,
  departments: string,
  organization: string,
  location: string,
  employmentType: string,
  seniorityLevel: string,
  jobId: number,
  title: string,
  content: string,
  firstPublishedAt: string,
});

export const JobShape = shape({
  headline: string,
  greenhouseData: GreenhouseDataShape,
});

export const FaqItemShape = shape({
  categories: arrayOf(object),
  question: string,
  answer: RichTextShape,
});

export const GreenhouseQuestionShape = shape({
  name: string.isRequired,
  required: bool,
  value: oneOfType([string, number]),
  label: string,
  description: string,
  type: string,
  values: arrayOf(
    shape({
      value: oneOfType([string, number]).isRequired,
      label: string.isRequired,
    })
  ),
});

export const DownloadElementShape = shape({
  id: string.isRequired,
  headline: string.isRequired,
  contentDescription: string,
  picture: object,
  files: array.isRequired,
});

export const KpiShape = arrayOf(
  shape({
    number: string.isRequired,
    text: string.isRequired,
  })
);
