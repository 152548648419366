import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useIntersectionEffect } from '../../../shared/hooks/use-intersection-effect/use-intersection-effect';

import styles from './bubble.module.scss';

function Bubble({ verticalPosition, horizontalPosition, size }) {
  const [isShrunk, setIsShrunk] = useState(true);
  const bubbleElement = useRef(null);

  useIntersectionEffect(bubbleElement, () => setIsShrunk(false), {
    threshold: 0.1,
  });

  return (
    <div
      ref={bubbleElement}
      className={classNames(
        styles.bubble,
        styles[verticalPosition],
        styles[horizontalPosition],
        styles[size],
        {
          [styles.shrunk]: isShrunk,
          [styles.expanded]: !isShrunk,
        }
      )}
    >
      <div className={styles.sizing}>
        <div className={styles.shape} />
      </div>
    </div>
  );
}

Bubble.propTypes = {
  size: PropTypes.string,
  horizontalPosition: PropTypes.string,
  verticalPosition: PropTypes.string,
};

export default Bubble;
