import { isEmpty } from 'lodash/fp';
import { useEffect, useState } from 'react';
import {
  COOKIE_POLICIES,
  getCurrentCookiePolicy,
} from '../../../services/cookies/cookies';

function useTracking(trackingData) {
  const [initialDataLayer] = useState(trackingData);

  /**
   * Use this instead of the default Google Tag Manager `push` method
   * because it takes the cookies into consideration.
   * @param trackingData = {
   *  event: EVENTS,
   *  ctaType: CTA_TYPE,
   *  {...otherDataYouWantToPush}
   */
  const pushTracking = trackingData => {
    if (getCurrentCookiePolicy() === COOKIE_POLICIES.DECLINE_ALL) {
      return;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(trackingData);
  };

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];

    if (
      isEmpty(initialDataLayer) ||
      getCurrentCookiePolicy() === COOKIE_POLICIES.DECLINE_ALL
    ) {
      return;
    }

    window.dataLayer.push({ ...initialDataLayer });
  }, [initialDataLayer]);

  return {
    pushTracking,
  };
}

export default useTracking;
