import { useEffect, useRef } from 'react';

export function useIntersectionEffect(
  elementRef,
  effect,
  options = {
    root: null,
    rootMargin: '0px',
    threshold: 1.0,
  }
) {
  const observer = useRef(null);

  useEffect(() => {
    const deferredEffect = ([entry]) => {
      // wrapped to prevent callback from running immediately on render, regardless of intersection
      if (entry.isIntersecting) {
        effect(entry);
      }
    };

    if (!observer.current) {
      observer.current = new IntersectionObserver(deferredEffect, options);
    }

    observer.current.observe(elementRef.current);

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [elementRef, effect, options]);
}
