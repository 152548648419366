import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { useCookiePolicy } from '../../services/cookies/cookies';

export const CookieContext = createContext({
  cookie: null,
  setCookie: () => {},
});
CookieContext.displayName = 'CookieContext';

export const CookieContextProvider = ({ children }) => {
  const [cookiePolicy, setCookiePolicy] = useCookiePolicy();
  return (
    <CookieContext.Provider
      value={{ cookie: cookiePolicy, setCookie: setCookiePolicy }}
    >
      {children}
    </CookieContext.Provider>
  );
};

CookieContextProvider.propTypes = {
  children: PropTypes.node,
};
